/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';

import timeShifter from '../../../../components/util/time-shifter';
import localNetworkProfile from '../../../../components/session/local-network-profile';

import template from './chart-options.html';
import modalTemplate from './chart-options-modal.html';
import {ADN_CHART_COUNT_TYPES, ADN_CHART_STAT_TYPES, ADN_CHART_Y_AXIS_OPTIONS} from "../../options/chart-options";

const MODULE_NAME = "chart-options-directive";

export function getAvailableFields(ChartOptionsUtil, localUserProfile, chartType, $location) {
  const chartOptions = ChartOptionsUtil.getOptions(chartType);

  const profile = localUserProfile.get().profile;
  const profileParam = chartOptions.chartTotalsOptions;
  const availableFields = _.get(profile, [profileParam, 'availableFields']);
  return _.filter(chartOptions.fields, function(f) {
    const isUserLimited = $location.host === "localhost" || (f.user && f.user !== localUserProfile.get("username"));
    return !isUserLimited && (!availableFields || availableFields.indexOf(f.id) > -1);
  });
}

angular.module(MODULE_NAME, [
  localNetworkProfile,
  timeShifter
])
  .directive('adnChartOptions', function($rootScope, $uibModal, UserProfile, $document, adnTimeShifter, ChartOptionsUtil, localUserProfile, LocalNetworkProfile, $timeout, $location) {
    return {
      restrict: 'A',
      scope: {
        adnDateOptionId: '@',
        adnStartDate: '<',
        adnEndDate: '<',
        adnCountTypeId: '@',
        adnStatTypeId: '@',
        adnShowExpandedDownload: '@',
        adnChartType: '@?',
        adnSelFieldIds: '<?',
        adnSelFieldId: '<?',
        adnYAxisOptionId: '@?',
        adnChangeCallback: '&',
        adnSaveCallback: '&?',
        adnIsCollection: '=?',
        adnCurrency: '@?',
        adnMinimalCollection: '<?',
        adnDateSelectorId: '@',
        adnOptions: '@?'
      },
      template: template,
      link: function(scope) {
        scope.adnOptions = scope.adnOptions || 'LINE_CHART_OPTIONS';
        scope.adnIsCollection = scope.adnIsCollection || false;
        scope.adnMinimalCollection = scope.adnMinimalCollection === true;

        scope.dateOptions = ChartOptionsUtil.getDateOptions(scope.adnChartType);
        scope.dateOptionsFiltered = _.filter(scope.dateOptions, function(d) {
          return !d.undated;
        });
        scope.countTypes = ADN_CHART_COUNT_TYPES;
        scope.statTypes = ADN_CHART_STAT_TYPES;
        scope.yAxisOptions = ADN_CHART_Y_AXIS_OPTIONS;
        scope.sel = {};
        scope.manualDateChange = false;

        scope.availableCurrencies = [];
        const preferredCurrency = localUserProfile.getPreferredCurrency();
        const defaultCurrency = LocalNetworkProfile.get('defaultCurrency');
        if (scope.adnCurrency) {
          const chartType = ChartOptionsUtil.getOptions(scope.adnChartType);
          const currencyType = chartType && chartType.type === 'PUBLISHING' ? 'Floor Price' : 'Bid';
          scope.availableCurrencies.push({code: scope.adnCurrency, text: scope.adnCurrency + " - " + currencyType + " Currency"});
        }
        if (preferredCurrency) {
          scope.availableCurrencies.push({code: preferredCurrency, text: preferredCurrency + " - Preferred Currency"});
        }
        scope.availableCurrencies.push({code: defaultCurrency, text: defaultCurrency + " - Network Currency"});
        scope.availableCurrencies = _.uniqBy(scope.availableCurrencies, "code");
        if (scope.availableCurrencies.length === 1) {
          scope.availableCurrencies = [];
        }

        scope.showAudit = {val: false};
        scope.sel.currency = scope.adnCurrency || preferredCurrency || defaultCurrency;

        scope.selectFields = function() {
          const body = $document[0].getElementsByTagName("body")[0];
          body.click();

          const modalInstance = $uibModal.open({
            template: modalTemplate,
            windowClass: '',
            controller: function() {
              const ctrl = this;

              const chartOptions = ChartOptionsUtil.getOptions(scope.adnChartType);
              ctrl.fields = chartOptions.fields;

              let localUser = localUserProfile.get();
              let profile = localUserProfile.get().profile;
              const profileParam = chartOptions.chartTotalsOptions;

              const availableFields = _.get(profile, [profileParam, 'availableFields'], []);
              ctrl.sel = {availableFields: availableFields};
              if (!availableFields.length) {
                ctrl.sel.availableFields = _.map(ctrl.fields, function(f) {
                  return f.id;
                });
              }

              ctrl.update = function() {
                profile[profileParam] = {
                  availableFields: ctrl.sel.availableFields,
                };
                UserProfile.save({
                  id: localUser.userId,
                  profile: _.pick(profile, profileParam)
                }, function(apiUser) {
                  modalInstance.close({save: true});
                  localUser = localUserProfile.safeSaveProfile(profileParam, apiUser.profile[profileParam]);
                  profile = localUser.profile;
                });
              };
            },
            controllerAs: 'modalCtrl',
            size: 'md',
          });
          modalInstance.result.then(function(result) {
            if (result.save) {
              scope.updateChart('availableFields');
            }
          });
        };

        let setDates = function(selectedDateOption) {
          scope.dates = scope.dates || {};
          if (selectedDateOption) {
            scope.dates.startDate = scope.sel.dateOption.undated ? undefined : adnTimeShifter.timeShift(scope.sel.dateOption.startDate().toDate());
            scope.dates.endDate = scope.sel.dateOption.undated ? undefined : adnTimeShifter.timeShift(scope.sel.dateOption.endDate().toDate());
            return;
          }
          if (!scope.dates.startDate || !scope.dates.endDate) {
            const startDateString = moment.parseZone(scope.adnStartDate).format('YYYY-MM-DD HH:mm');
            const endDateString = moment.parseZone(scope.adnEndDate).format('YYYY-MM-DD HH:mm');
            if (_.get(scope, ['sel', 'dateOption'])) {
              scope.dates.startDate = scope.sel.dateOption.startDate().toDate();
              scope.dates.endDate = scope.sel.dateOption.endDate().toDate();
            } else {
              scope.dates.startDate = moment(startDateString).toDate();
              scope.dates.endDate = moment(endDateString).toDate();
            }
          }
        };

        const resetFields = {method: _.noop};
        if (scope.adnOptions === 'LINE_CHART_OPTIONS') {
          resetFields.method = function() {
            const selectableFields = getAvailableFields(ChartOptionsUtil, localUserProfile, scope.adnChartType, $location);
            scope.fields = _.filter(selectableFields, function(f) {
              return !f.availability || (LocalNetworkProfile.isPureMarketplacePlatform() && f.availability.MARKETPLACE) || (!LocalNetworkProfile.isPureMarketplacePlatform() && f.availability.DIRECT);
            });
            scope.sel.fields = _.filter(scope.fields, function(value) {
              scope.adnSelFieldIds = _.isArray(scope.adnSelFieldIds) ? scope.adnSelFieldIds : [];
              return scope.adnSelFieldIds.indexOf(value.id) > -1;
            });
            scope.sel.field = scope.adnSelFieldId;
          };
          resetFields.method();
        }

        if (scope.adnDateOptionId) {
          scope.sel.dateOption = ChartOptionsUtil.getDateOptions(scope.adnChartType)[scope.adnDateOptionId];
        }
        if (!scope.sel.dateOption && (!scope.adnStartDate || !scope.adnEndDate)) {
          scope.sel.dateOption = scope.dateOptions.last12Hours;
        }

        scope.sel.yAxisOption = scope.adnYAxisOptionId ? ADN_CHART_Y_AXIS_OPTIONS[scope.adnYAxisOptionId] : scope.yAxisOptions.multiple;
        scope.sel.countType = scope.adnCountTypeId ? ADN_CHART_COUNT_TYPES[scope.adnCountTypeId] : scope.countTypes.singular;
        scope.sel.statType = scope.adnStatTypeId ? ADN_CHART_STAT_TYPES[scope.adnStatTypeId] : scope.statTypes.impression;
        setDates(_.get(scope, ['sel', 'dateOption']));

        scope.dateChangeCallback = function(resetTimes) {
          scope.manualDateChange = true;

          if (resetTimes) {
            const dates = adnTimeShifter.startAndEndOfDays(scope.dates.startDate, scope.dates.endDate);
            scope.dates.startDate = dates.startDate;
            scope.dates.endDate = dates.endDate;
          }
        };

        scope.updateDates = function(selectedDateOption) {
          scope.datesError = "";
          if (selectedDateOption) {
            scope.manualDateChange = false;
          } else {
            if (moment(scope.dates.startDate).isAfter(moment(scope.dates.endDate))) {
              scope.datesError = "The end date must be after the start date.";
              return;
            }
            scope.sel.dateOption = null;
            scope.manualDateChange = false;
          }
          setDates(selectedDateOption);
          scope.updateChart('dates', true);
        };

        const sendRootScope = function(isDateChange, isCurrencyChange) {
          const dateOptionId = _.get(scope.sel.dateOption, "id");
          let correctDates = {startDate: undefined, endDate: undefined, dateOptionId: dateOptionId};
          if (dateOptionId !== 'useDates') {
            const startDate = dateOptionId ? adnTimeShifter.timeShift(scope.dates.startDate, true) : moment(scope.dates.startDate);
            const endDate = dateOptionId ? adnTimeShifter.timeShift(scope.dates.endDate, true) : moment(scope.dates.endDate);

            const startDateString = moment(startDate).format('YYYY-MM-DD HH:mm');
            const endDateString = moment(endDate).format('YYYY-MM-DD HH:mm');
            const timelessTimezonedStartDate = moment.tz(startDateString, ChartOptionsUtil.getNetworkTimezone());
            const timelessTimezonedEndDate = moment.tz(endDateString, ChartOptionsUtil.getNetworkTimezone());
            const correctStartDate = dateOptionId ? startDate : timelessTimezonedStartDate;
            const correctEndDate = dateOptionId ? endDate : timelessTimezonedEndDate;

            correctDates = {startDate: correctStartDate, endDate: correctEndDate, dateOptionId: dateOptionId, currency: scope.sel.currency};
          }
          const chartType = ChartOptionsUtil.getOptions(scope.adnChartType);
          if ((isCurrencyChange || isDateChange) && chartType) {
            $timeout(function() {
              $rootScope.$emit('chartDates' + chartType.idKey + (scope.adnDateSelectorId || ''), correctDates);
            }, 101);
          }
          return correctDates;
        };
        sendRootScope(true);
        scope.updateChart = function(prefix, isDateChange, isCurrencyChange) {
          if (prefix === 'availableFields') {
            resetFields.method();
          }
          scope[prefix + 'Delay'] = true;

          $timeout(function() {
            scope[prefix + 'Delay'] = false;
          }, 21);

          $timeout(function() {
            let theDates = sendRootScope(isDateChange, isCurrencyChange);
            scope.adnChangeCallback({
              dateOptionId: theDates.dateOptionId,
              startDate: theDates.startDate,
              endDate: theDates.endDate,
              countTypeId: scope.sel.countType.id,
              statTypeId: scope.sel.statType.id,
              yAxisOptionId: scope.sel.yAxisOption.id,
              selFieldIds: _.map(scope.sel.fields, 'id'),
              selFieldId: scope.sel.field,
              selCurrency: scope.sel.currency,
              showAudit: scope.showAudit.val
            });
          }, 101);
        };

        scope.saveXls = function(allFields, totalsOnly) {
          scope.adnSaveCallback({allFields: allFields, format: 'xlsx', totalsOnly: totalsOnly});
        };
      }
    };
  });

export default MODULE_NAME;