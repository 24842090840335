/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import Highcharts from 'highcharts';
import highchartsNg from 'highcharts-ng';

import {CHART_COLORS} from '../options/chart-colors';
import chartOptionsDirective, {getAvailableFields} from '../directives/chart-options/chart-options-directive';
import promiseSpinnerComponent from '../directives/html/promise-spinner-component';
import resources from '../../../components/api/resources/resources';
import localNetworkProfile from '../../../components/session/local-network-profile';
import perfTotals from './performance-totals-component';
import auditHistoryComponent from "../../line-item/widget/audit-history-component";

import {Downloader} from "../../../components/util/downloader";

import template from './performance.html';
import {ADN_CHART_COUNT_TYPES, ADN_CHART_Y_AXIS_OPTIONS, ALT_IDS} from "../options/chart-options";
import {ObjectTypeFinder} from "../constants/object-type";

const MODULE_NAME = "performance-directive";

angular.module(MODULE_NAME, [
  highchartsNg,
  perfTotals,
  chartOptionsDirective,
  resources,
  promiseSpinnerComponent,
  localNetworkProfile,
  auditHistoryComponent
])

  .directive('adnPerformanceChart', function($locale, $translate, $log, $filter, $location, localUserProfile, LocalNetworkProfile, ChartOptionsUtil, UserProfile, statsResource) {
    return {
      restrict: 'A',
      scope: {
        obj: '<adnPerformanceChart',
        subObj: '<',
        chartType: '@adnChartType',
        idKey: '@',
        useAlt: '<',
        absChunkMin: '@',
        groupBy: '@',
        groupById: '@',
        showCollapsed: '@',
        dateSelectorId: '@',
        currency: '<'
      },
      template: template,
      link: {
        // using preLink to fill out chart config BEFORE the highchart directive is compiled
        pre: function preLink(scope) {
          scope.isCollection = !_.get(scope.obj, 'id');
          scope.expanded = false;

          scope.isMarketplace = LocalNetworkProfile.isPureMarketplacePlatform();

          let chartOptions = ChartOptionsUtil.getOptions(scope.chartType, scope.isMarketplace),
            localUser = localUserProfile.get(),
            profile = localUser.profile,
            profileStartDate = _.get(profile[chartOptions.perfChartOptions], 'startDate'),
            profileEndDate = _.get(profile[chartOptions.perfChartOptions], 'endDate'),
            profileCountType = ADN_CHART_COUNT_TYPES[_.get(profile[chartOptions.perfChartOptions], 'countTypeId')],
            profileYAxisOption = ADN_CHART_Y_AXIS_OPTIONS[_.get(profile[chartOptions.perfChartOptions], 'yAxisOptionId')],
            profileSelFieldIds = _.get(profile[chartOptions.perfChartOptions], 'selFieldIds'),
            profileSelFieldId = _.get(profile[chartOptions.perfChartOptions], 'selFieldId'),
            fieldTranslateFunc = function(m) {
              m.name = $filter('adnFieldTranslate')('chart.statType.' + (m.key || m.tKey || m.id), m.name);
              return m;
            },
            getSelFields = function(allFields) {
              if (!scope.isCollection && !scope.subObj) {
                let map = _.map(_.filter(angular.copy(chartOptions.fields), function(value) {
                  return (allFields || scope.selFieldIds.indexOf(value.id) > -1) && value.id !== 'customFields';
                }), fieldTranslateFunc);
                if (scope.yAxisOption === ADN_CHART_Y_AXIS_OPTIONS.shared) {
                  _.forEach(map, function(c) {
                    c.yAxis = 0;
                  });
                }
                return map;
              }
              return _.map(_.map(scope.subObj || scope.obj, function(c) {
                return {
                  data: [],
                  id: c.id,
                  name: c.name,
                  yAxis: 0
                };
              }), fieldTranslateFunc);
            };

          scope.dateOption = ChartOptionsUtil.getDateOptions(scope.chartType)[_.get(profile[chartOptions.perfChartOptions], 'dateOptionId')];
          if (!scope.dateOption && (!profileStartDate || !profileEndDate)) {
            scope.dateOption = ChartOptionsUtil.getDateOptions().last12Hours;
          }
          scope.startDate = scope.dateOption ? scope.dateOption.startDate() : moment(profileStartDate);
          scope.endDate = scope.dateOption ? scope.dateOption.endDate() : moment(profileEndDate);
          scope.countType = profileCountType ? profileCountType : ADN_CHART_COUNT_TYPES.singular;
          scope.yAxisOption = profileYAxisOption ? profileYAxisOption : ADN_CHART_Y_AXIS_OPTIONS.shared;
          scope.selFieldIds = profileSelFieldIds ? profileSelFieldIds : [_.find(_.map(chartOptions.fields, 'id'), function(id) { return id === 'rendered';})];
          scope.selFieldId = profileSelFieldId ? profileSelFieldId : _.find(_.map(chartOptions.fields, 'id'), function(id) {return id === 'rendered';});
          scope.changeHook = {
            chartChangeHandlers: [],
            getTotals: () => {
              return scope.totals;
            }
          };

          let timezone = LocalNetworkProfile.get('timeZone');
          let networkCurrency = LocalNetworkProfile.get("defaultCurrency");

          let networkTimezoneOffset = moment.tz(timezone).utcOffset();

          let advStatsSeries = getSelFields(),
            createChartConfig = function(type, series, yAxisData, height) {
              return {
                chart: {
                  type: type,
                  zoomType: 'x',
                  showAxes: true,
                  height: height
                },
                plotOptions: {
                  series: {
                    marker: {
                      enabled: false
                    },
                    turboThreshold: 2000
                  }
                },
                tooltip: {
                  shared: true
                },
                xAxis: {
                  type: 'datetime',
                  title: {
                    enabled: false,
                    text: 'Date and Time'
                  }
                },
                yAxis: yAxisData,
                series: series,
                title: {
                  text: null
                },
                credits: {
                  enabled: false
                },
                accessibility: {
                  enabled: false
                },
                exporting: {
                  enabled: true
                }
              };
            };

          const statsHook = {};

          let getYAxes = function() {
            if (scope.isCollection || _.isArray(scope.subObj)) {
              return {
                title: {text: null},
                min: 0,
                floor: 0,
                opposite: false,
                id: 0
              };
            }
            const standardFields = [];
            // doing it this way because having a standard set of axes is better for Highcharts than an adjustable set of axes with the IDs and the indexes out of whack
            for (let i = 0; i < 100; i++) {
              const theColor = CHART_COLORS[i];

              const foundField = _.find(chartOptions.fields, function(f) {
                return f.yAxis === i;
              }) || {};
              standardFields.push({
                title: {text: null},
                min: 0,
                opposite: false,
                allowDecimals: foundField.yAxisOption === ADN_CHART_Y_AXIS_OPTIONS.shared ? true : !!foundField.allowDecimals,
                id: i,
                labels: {style: {color: theColor}}
              });
            }
            return standardFields;
          };
          scope.statsChart = createChartConfig('line', advStatsSeries, getYAxes(), 280);

          let chunkYValue = function(yval) {
            if (_.has(yval, 'amount')) {
              return yval.amount;
            } else if (_.has(yval, 'count')) {
              return yval.count;
            }
            return yval;
          };

          let getCollDataPoint = function(theChunks, objectId) {
            let objParam = "creative";
            if (scope.chartType === 'LINE_ITEM') {
              objParam = "lineItem";
            } else if (scope.chartType === 'AD_UNIT') {
              objParam = "adUnit";
            } else if (scope.chartType === 'SEGMENT') {
              objParam = "folder";
            } else if (scope.chartType === 'EXTERNAL_AD_UNIT') {
              objParam = "externalAdUnit";
            }

            return _.get(_.find(theChunks, function(c) {
              return c[objParam].id === objectId;
            }), (scope.useAlt ? (ALT_IDS[scope.selFieldId] || scope.selFieldId) : scope.selFieldId)) || 0;
          };

          let previousStats = {};
          let startStatsGet = function() {
            let chunkSize = ChartOptionsUtil.getChunkSize(scope.startDate, scope.endDate, scope.absChunkMin);
            let statsParams = {
              id: !scope.isCollection ? scope.obj.id : _.map(scope.obj, 'id').join(';'),
              idKey: scope.idKey || chartOptions.idKey,
              startDate: scope.startDate,
              endDate: scope.endDate,
              groupBy: scope.groupBy || chunkSize.key,
              currency: scope.currency || localUserProfile.getPreferredCurrency()
            };
            if (scope.subObj) {
              statsParams.aggregateTo = scope.chartType;
            }
            if (!scope.startDate) {
              delete statsParams.startDate;
              delete statsParams.endDate;
              statsParams.groupBy = 'AUTO_SCALE';
            }
            if (scope.groupBy || scope.isCollection) {
              statsParams.aggregateTo = scope.groupBy ? chunkSize.key : scope.chartType;

              const field = _.find(chartOptions.fields, ['id', scope.selFieldId]);
              if (field) {
                _.forEach(scope.statsChart.series, function(series) {
                  series.isPercentage = field.isPercentage;
                  series.allowDecimals = field.allowDecimals;
                  series.tooltip = field.tooltip;
                });
              }
            }

            statsHook.statsSuccessCallback = function (stats, theSeries) {
              previousStats = {
                statsParams: statsParams,
                stats: stats
              };
              Highcharts.setOptions({
                global: {timezoneOffset: networkTimezoneOffset * -1},
                lang: {
                  shortMonths: $locale.DATETIME_FORMATS.SHORTMONTH,
                  months: $locale.DATETIME_FORMATS.MONTH,
                  weekdays: $locale.DATETIME_FORMATS.DAY,
                  thousandsSep: $locale.NUMBER_FORMATS.GROUP_SEP || ' ',
                  decimalPoint: $locale.NUMBER_FORMATS.DECIMAL_SEP || '.'
                }
              });

              const theStats = scope.groupById ? _.find(stats.chunks, function (c) {
                return _.get(c, ['folder', 'id']) === scope.groupById;
              }) : stats;
              const theChunks = _.get(theStats, 'chunks');

              scope.totals = _.get(theStats, 'totals') || stats;
              scope.error = false;

              let extras = scope.groupBy || scope.isCollection || scope.idKey || _.indexOf(scope.selFieldIds, 'customFields') < 0 ? [] : ChartOptionsUtil.getLineItemCustomFields(stats.totals);
              scope.statsChart.yAxis = getYAxes();

              let allSeries = theSeries || scope.statsChart.series;
              _.forEach(extras, function (ex) {
                let theAxis = _.find(scope.statsChart.yAxis, function (axis) {
                  return axis.name === ex;
                });
                allSeries.push({
                  name: ex,
                  id: ex,
                  data: [],
                  yAxis: theAxis ? (theAxis.id || 0) : 0,
                  allowDecimals: false
                });
              });

              let keyedChunks = _.keyBy(theChunks, 'chunkStart');
              let colorCache = [];
              _.forEach(allSeries, function (series, loopCounter) {
                if (scope.yAxisOption === ADN_CHART_Y_AXIS_OPTIONS.shared) {
                  series.yAxis = 0;
                } else if (!scope.isCollection && !scope.idKey) {
                  series.yAxis = _.get(chartOptions.fields[series.id], 'yAxis') || series.yAxis;
                }

                let yAxisFieldId = _.get(chartOptions.fields[series.id], 'yAxis');
                let yAxisFieldColor = _.get(scope.statsChart.yAxis[yAxisFieldId], 'labels.style.color');
                series.color = scope.isCollection || scope.idKey ? CHART_COLORS[loopCounter % CHART_COLORS.length] : yAxisFieldColor || CHART_COLORS[loopCounter % CHART_COLORS.length];
                if (colorCache.indexOf(series.color) > -1) {
                  series.color = _.find(CHART_COLORS, function (c) {
                    return c !== series.color;
                  });
                }
                colorCache.push(series.color);

                let runningTotal = 0;
                series.data = _.map(stats.chunkRange, function (statsDate) {
                  let dataPoint = null;
                  if (keyedChunks[statsDate]) {
                    dataPoint = scope.isCollection || scope.idKey ? getCollDataPoint(keyedChunks[statsDate].chunks, scope.useAlt ? (series.altId || series.id) : series.id) : keyedChunks[statsDate][scope.useAlt ? (series.altId || series.id) : series.id];
                  }
                  let value = chunkYValue(dataPoint) || 0;
                  if (series.isPercentage) {
                    value = value * 100;
                    runningTotal = value;
                  } else {
                    runningTotal += value;
                  }
                  return {
                    x: moment(statsDate).valueOf(),
                    y: scope.countType === ADN_CHART_COUNT_TYPES.cumulative && !series.singularOnly ? runningTotal : value
                  };
                });
              });
              scope.haveStats = true;
              scope.noData = !scope.statsChart.series.length;
              scope.isPending = false;

              _.forEach(scope.changeHook.chartChangeHandlers, function(f) {
                f(scope.totals);
              });
            };

            if (_.isEqual(previousStats.statsParams, statsParams)) {
              return statsHook.statsSuccessCallback(previousStats.stats);
            }
            if (!_.includes(['hourly', 'half_hourly'], statsParams.groupBy.toLowerCase()) && statsParams.currency !== networkCurrency && !scope.groupBy) {
              statsParams.groupBy = 'DAILY';
            }
            if (scope.showAudit && scope.chartType === 'LINE_ITEM') {
              scope.auditHook = scope.auditHook || {};
              scope.auditHook.startDate = statsParams.startDate;
              scope.auditHook.endDate = statsParams.endDate;
              scope.auditHook.chartMode = true;
              if (scope.auditHook.triggerAuditQuery) {
                scope.auditHook.triggerAuditQuery();
              }
            }
            statsResource.get(statsParams, statsHook.statsSuccessCallback, function(data) {
              if (!data.invalidDate) {
                $log.warn(data);
              }
              if (data.invalidDate) {
                scope.error = 'errors.api.dateOption';
              } else if (data.status !== -1) {
                scope.error = 'errors.api.stats';
              }
              scope.isPending = false;
            }, scope);
          };
          startStatsGet();

          scope.changeCallback = function(dateOptionId, startDate, endDate, countTypeId, statTypeId, yAxisOptionId, selFieldIds, selFieldId, selCurrency, showAudit) {
            scope.dateOption = ChartOptionsUtil.getDateOptions(scope.chartType)[dateOptionId];
            scope.startDate = startDate;
            scope.endDate = endDate;
            scope.countType = ADN_CHART_COUNT_TYPES[countTypeId];
            scope.selFieldIds = selFieldIds;
            scope.selFieldId = selFieldId;
            scope.currency = selCurrency;
            scope.yAxisOption = ADN_CHART_Y_AXIS_OPTIONS[yAxisOptionId];
            scope.statsChart.series = getSelFields();
            scope.showAudit = showAudit;
            startStatsGet();

            profile[chartOptions.perfChartOptions] = _.assign({}, profile[chartOptions.perfChartOptions], {
              dateOptionId: _.get(scope.dateOption, 'id'),
              startDate: moment(scope.startDate).format(),
              endDate: moment(scope.endDate).format(),
              yAxisOptionId: scope.yAxisOption.id,
              countTypeId: scope.countType.id,
              selFieldIds: scope.selFieldIds,
              selFieldId: scope.selFieldId
            });

            UserProfile.save({
              id: localUser.userId,
              profile: _.pick(profile, chartOptions.perfChartOptions)
            }, function(apiUser) {
              localUser = localUserProfile.safeSaveProfile(chartOptions.perfChartOptions, apiUser.profile[chartOptions.perfChartOptions]);
              profile = localUser.profile;
            });
          };

          scope.save = function(allFields, format, totalsOnly) {
            const selectableFields = _.map(getAvailableFields(ChartOptionsUtil, localUserProfile, scope.chartType, $location), "id");
            const allTotals = angular.copy(previousStats.stats.totals);
            const allDataFields = _.filter(getSelFields(allFields), function(f) {
              return scope.subObj || selectableFields.indexOf(f.id) > -1 && !_.isNil(allTotals[f.id]);
            });
            const fields = _.map(allDataFields, 'id');

            const rows = [];
            if (!totalsOnly) {
              const refData = angular.copy(scope.statsChart.series[0].data);
              statsHook.statsSuccessCallback(previousStats.stats, allDataFields);
              for (let i = 0; i < refData.length; i++) {
                let dateData = {};
                for (let j = 0; j < allDataFields.length; j++) {
                  if (j === 0) {
                    dateData.date = allDataFields[j].data[i].x;
                  }
                  dateData[allDataFields[j].id] = allDataFields[j].data[i].y;
                }
                rows.push(dateData);
              }
            }

            const totals = _.pick(allTotals, selectableFields);
            _.forEach(allDataFields, function(f) {
              if (f.isPercentage) {
                totals[f.id] = totals[f.id] * 100;
              }
            });
            totals.date = "TOTALS";
            rows.push(totals);

            const headingFields = _.map(allDataFields, "name");
            headingFields.unshift(totalsOnly ? "Totals" : "Date");
            fields.unshift("date");

            if (rows.length > 0 || totalsOnly) {
              let keysLength = _.keys(rows[0]).length;
              if (headingFields.length !== keysLength || fields.length !== keysLength) {
                _.forEach(_.keys(rows[0]), function(k) {
                  // this should be adding the custom fields
                  if (fields.indexOf(k) < 0 && fields.length !== keysLength) {
                    fields.push(k);
                    totals[k] = allTotals[k];
                  }
                  if (headingFields.indexOf(k) < 0 && headingFields.length !== keysLength) {
                    headingFields.push(k);
                  }
                });
              }
            }

            const date = scope.dateOption ? $translate.instant('chart.dateOption.' + scope.dateOption.id) : "custom dates";
            const objectType = ObjectTypeFinder.findByCaps(scope.chartType);
            const defaultName = ((scope.isCollection || scope.idKey ? ('All ' + (objectType ? objectType.name : "")) : scope.obj.name) + " " + date + ".xls").replace(/ /g, "-");
            new Downloader().saveXls2(defaultName, headingFields, fields, rows, allDataFields, true);
          };
        },
        post: function postLink(scope, element) {
          element.addClass('chart');
        }
      }
    };
  });

export default MODULE_NAME;