/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiRouter from '@uirouter/angularjs';
import {OBJECT_TYPE} from "../../common/constants/object-type";

import resources from '../../../components/api/resources/resources';
import adnShotter from "../../../components/util/adn-shotter";
import {ApiConfig} from "../../../components/api/api";

const MODULE_NAME = "shotter-ctrl";

angular.module(MODULE_NAME, [
  uiRouter,
  resources,
  adnShotter,
])
  .controller('ShotterCtrl', function($state, $stateParams, localUserProfile, LocalNetworkProfile, adnShotter) {
    const ctrl = this;

    const app = localUserProfile.getCurrentApp();
    const profileKey = 'adnLastViewed' + LocalNetworkProfile.getNetworkId() + (app === 'ADVERTISING' ? '' : app);
    ctrl.lastViewed = _.map(_.get(localUserProfile.get(), ['profile', profileKey]), function(entry) {
      const objType = OBJECT_TYPE[entry.objectType];
      if (objType) {
        entry.objType = objType;
      }
      return entry;
    });
    ctrl.lastViewedObject = _.get(ctrl.lastViewed, [0]);
    ctrl.selectFrom = 'CUSTOM';

    ctrl.url = "https://www.vg.no/nyheter/i/8q7POE/krigen-i-midtoesten-trenger-ikke-aa-vente-paa-trump";
    ctrl.width = 300;
    ctrl.height = 250;
    ctrl.device = 'DESKTOP';

    ctrl.updateScript = function() {
      const theChildren = [];
      theChildren.push({
        cId: ctrl.objectId,
        w: ctrl.width,
        h: ctrl.height
      });
      const env = ApiConfig.obtain().getId().replace("_adn", "");
      ctrl.theScript = "var scriptEl = document.createElement(\"script\");scriptEl.src=(\"https://cdn.adnuntius.com/shotter.js?env=" + env + "&cjson=" + encodeURIComponent(JSON.stringify(theChildren)) + "&networkId=" + LocalNetworkProfile.getNetworkTag() + "\");document.body.appendChild(scriptEl);";
    };

    function fetchShots() {
      ctrl.screenshotting = true;
      adnShotter.fetchAll().then(function(data) {
        ctrl.screenshots = data;
        _.forEach(ctrl.screenshots, function(s) {
          s.articleUrlHttps = (s.articleUrl || "").indexOf("http") === 0 ? s.articleUrl : "https://" + s.articleUrl;
        });
      }).finally(function() {
        ctrl.screenshotting = false;
      });
    }
    ctrl.refresh = function() {
      fetchShots();
    };

    ctrl.deleteScreenshot = function(screenshot, index) {
      ctrl.deleting = true;
      adnShotter.deleteScreenshot(screenshot).then(function() {
        _.pullAt(ctrl.screenshots, index);
      }).finally(function() {
        ctrl.deleting = false;
      });
    };

    ctrl.loaded = true;
    ctrl.objectId = $state.previousParams.id;
    ctrl.objectType = _.find(OBJECT_TYPE, function(ot) {
      return ot.stateName === $state.previousState.name;
    });
    ctrl.objectTypes = OBJECT_TYPE;
    ctrl.hashResults = true;

    ctrl.doCheck = function() {
      ctrl.loaded = false;
      adnShotter.takeShot(ctrl.objectId, null, ctrl.url, ctrl.device, ctrl.bypassSave, ctrl.headless).then(function(data) {
        ctrl.loaded = true;
        ctrl.resp = data;
      });
    };
    if ($stateParams.creativeId) {
      ctrl.objectId = $stateParams.creativeId;
      ctrl.doCheck();
    }
  });

export default MODULE_NAME;