/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import ngFileUpload from 'ng-file-upload';
import _ from 'lodash';
import {ApiConfig} from "../api/api";

const MODULE_NAME = "adn-ad-density";

angular.module(MODULE_NAME, [
  ngFileUpload
])

  .factory('adnAdDensity', function($http) {
    return {
      fetchAll: function() {
        return $http.get(ApiConfig.obtain().getVersionedUri(['adnaddensity', 'fetchall']), {
          params: _.assign({}, {format: 'json', concise: true})
        }).then(_.iteratee('data'));
      },
      deleteEntry: function(add) {
        return $http.get(ApiConfig.obtain().getVersionedUri(['adnaddensity', 'deleteentry']), {
          params: _.assign({}, {format: 'json', concise: true, adDensityId: add.idOfAdDensity})
        }).then(_.iteratee('data'));
      },
      takeAdDensity: function(url, bypassSave) {
        return $http.get(ApiConfig.obtain().getVersionedUri(['adnaddensity', 'takeaddensity']), {
          params: _.assign({}, {format: 'json', concise: true, url: url, bypassSave: bypassSave})
        }).then(_.iteratee('data'));
      }
    };
  });

export default MODULE_NAME;